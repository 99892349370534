import RangeCalendar from "@/components/RangeCalendar.vue";

export default {
    components: {
        RangeCalendar,
    },
    data() {
        return {
            calendarBoundaries: null,
            loadedBoundariesKey: null,
        };
    },
    computed: {
        boundariesKey() {
            return `${this.getMP()}`;
        },
    },
    methods: {
        getMP() {
            return this.$route.params.mp;
        },
    },
    watch: {
        boundariesKey: {
            immediate: true,
            async handler() {
                this.calendarBoundaries = null;
                if (!this.getMP()) {
                    return;
                }
                const dates = await this.$store.dispatch(
                    "common/getCalendarDates",
                    {
                        mp: this.getMP(),
                    }
                );
                this.calendarBoundaries = [dates.date, dates.date2];
                this.loadedBoundariesKey = this.boundariesKey;
            },
        },
    },
};
