//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { SlideYUpTransition } from "vue2-transitions";
import { debounce } from "throttle-debounce"; //@V

const MIN_SEARCH_LEN = 2; //&:

export default {
  components: {
    SlideYUpTransition
  },
  props: {
    limit: {
      type: Number,
      default: 10
    },
    placeholder: {
      type: String,
      default: ""
    },
    loader: {
      type: Function
    },
    getRoute: {
      type: Function,
      default: null
    },
    textProp: {
      type: String,
      required: true
    },
    cleanOnSelect: {
      type: Boolean,
      default: false
    },
    simple: {
      type: Boolean,
      default: false
    },
    noDataText: {
      type: String,
      default: null
    },
    value: {
      type: String,
      default: ""
    },
    selectedMp: {
      type: String,
      default: ""
    },
    packetInput: {
      type: Boolean,
      default: false
    },
    limitLinesNumber: {
      type: Number,
      default: 20
    }
  },
  data() {
    return {
      search_input: this.value,
      search_input_focused: false,
      show_search_results: false,
      search_results: [],
      search_input_manual_selected: -1,
      loading: false,
      searchDebouncedFunction: null,
      //@V
      cols: 10000
    };
  },
  computed: {
    results() {
      return this.search_results.map(item => {
        return {
          item: item,
          route: this.getRoute && this.getRoute(item),
          text: this.formatSearchResult(item)
        };
      });
    }
  },
  methods: {
    focus_search_input() {
      this.search_input_focused = true;
      //// @V: if (this.search_input.length > 0) this.show_search_results=true
      if (this.search_input.length >= MIN_SEARCH_LEN) this.show_search_results = true; //!&:
    },

    onBlur() {
      this.unfocus_input();
    },
    unfocus_input(forceBlur = false) {
      if (forceBlur) {
        this.$refs.input.blur();
      }
      this.search_input_focused = false;
      this.show_search_results = false;
      this.search_input_manual_selected = -1;
    },
    change_search_input() {
      this.search_input_manual_selected = -1;
      ////@V: if (this.search_input.length == 0) {
      if (this.search_input.length < MIN_SEARCH_LEN) {
        //!&:
        this.show_search_results = false;
        this.search_results = [];
      }
      ////@V: if (this.search_input.length > 0 & !this.show_search_results) {
      if (this.search_input.length >= MIN_SEARCH_LEN && !this.show_search_results) {
        this.show_search_results = true;
      }
      ////@V:  if (this.search_input.length > 0) {
      if (this.search_input.length >= MIN_SEARCH_LEN) {
        //!&:
        ////&:SRC: this.search(this.search_input)
        this.searchDebounced(this.search_input); //@V
      }
    },

    searchDebounced(query) {
      this.loading = true;
      if (!this.searchDebouncedFunction) {
        //// @V: this.searchDebouncedFunction = debounce(300, this.search) //@V
        this.searchDebouncedFunction = debounce(500, this.search); //!&:
      }

      this.searchDebouncedFunction(query);
    },
    async search(query) {
      ////&: ждем пока завершится загрузка
      //// НЕ ТАК: if( this.loading == true ) return;

      this.loading = true;
      let data = this.loader ? await this.loader(query) : [];
      this.loading = false;
      this.search_results = data.slice(0, this.limit + 1);
      if (this.search_results.length) {
        this.search_input_manual_selected = 0;
      } else {
        this.search_input_manual_selected = -1;
      }
    },
    formatSearchResult(current) {
      /*
      //@V: подцветка 1го слова
      // let reggie = new RegExp(this.search_input, "ig");
      // let pos = current[this.textProp].search(reggie)
      // if (pos == -1) {
      //     return current[this.textProp]
      // } else {
      //     const len_search = this.search_input.length
      //     let res = `${current[this.textProp].substring(0, pos)}<span class="blue-text">${current[this.textProp].substring(pos, pos+len_search)}</span>${current[this.textProp].substring(pos+len_search)}`
      //     return res
      // }
      */

      //!&: SF: 2022-01-09 подцветка всех слов из query
      var query = this.search_input;
      var v = current[this.textProp];
      //! далее аналогично /salesfinder/src/components/GlobalSearch/GlobalSearch.vue :: const highlight = v => { ...
      //let aLIKE = query.trim().split(/[.,<> -]/).filter(w => w.length>=3);
      let aLIKE = query.trim().split(/[, ]/).filter(w => w.length >= MIN_SEARCH_LEN);
      //E(_T(_F), 'aLIKE=', aLIKE)

      var res = v;
      for (let iw = 0; iw < aLIKE.length; iw++) {
        let w = aLIKE[iw].trim();
        if (w == "") continue;
        let re = new RegExp(w, "ig");
        let pos = res.search(re);
        if (pos == -1) {
          continue;
        } else {
          //не попали на разметку?
          let qTag = 0;
          //смотрим влево
          for (let i = pos; i >= 0; i--) {
            if (res.substring(i, i + 1) == ">") {
              qTag = 0;
              break;
            } else if (res.substring(i, i + 1) == "<") {
              qTag = 1;
              break;
            }
          }
          if (qTag == 1) continue;
          //смотрим вправо
          for (let i = pos; i < res.length; i++) {
            if (res.substring(i, i + 1) == "<") {
              qTag = 0;
              break;
            } else if (res.substring(i, i + 1) == ">") {
              qTag = 1;
              break;
            }
          }
          if (qTag == 1) continue;
          let lW = w.length;
          res = `${res.substring(0, pos)}<span class="blue-text">${res.substring(pos, pos + lW)}</span>${res.substring(pos + lW)}`;
        }
      }
      return res;
    },
    select_prev_search_item() {
      if (this.search_results.length > 0) {
        this.search_input_manual_selected -= 1;
        if (this.search_input_manual_selected < 0) this.search_input_manual_selected = this.search_results.length - 1;
      }
    },
    select_next_search_item() {
      if (this.search_results.length > 0) {
        this.search_input_manual_selected += 1;
        if (this.search_input_manual_selected == this.search_results.length) this.search_input_manual_selected = 0;
      }
    },
    select_search_item(item) {
      if (!item) {
        if (this.search_input_manual_selected !== -1) {
          item = this.results[this.search_input_manual_selected];
        } else if (this.results && this.results.length) {
          item = this.results[0];
        } else {
          return;
        }
      }
      if (!item.route) {
        this.$emit("select", item.item);
        if (this.cleanOnSelect) {
          this.search_input = "";
        }
        this.unfocus_input(true);
        return;
      }
      this.$router.push(item.route);
      this.unfocus_input(true);
      // this.unfocus_search_input()
    },

    onSelect(item) {
      this.$emit("select", item.item);
      this.unfocus_input(true);
      if (this.cleanOnSelect) {
        this.search_input = "";
      }
    },
    limitLines() {
      let lines = this.search_input.split("\n");
      for (let i = 0; i < lines.length; i++) {
        if (lines[i].length <= this.cols) continue;
        let j = 0;
        let space = this.cols;
        while (j++ <= this.cols) {
          if (lines[i].charAt(j) === " ") space = j;
        }
        lines[i + 1] = lines[i].substring(space + 1) + (lines[i + 1] || "");
        lines[i] = lines[i].substring(0, space);
      }
      if (this.limitLinesNumber) {
        this.search_input = lines.slice(0, this.limitLinesNumber).join("\n");
      } else {
        this.search_input = lines.join("\n");
      }
    }
  },
  watch: {
    value(value) {
      this.search_input = value;
      this.change_search_input();
      this.$refs.input.focus();
    },
    selectedMp() {
      this.change_search_input();
    },
    search_input(value) {
      this.$emit("change-value", value);
    }
  },
  emits: ["change-value"]
};