//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import MainPage from "@/components/MainPage.vue";
import MainTitle from "@/components/MainTitle.vue";
import EntitySearch from "@/components/EntitySearch.vue";
import SellersTable from "@/components/Rating/SellersTable";
import { dateRangeFromDate, today, dateRangeBoundaries } from "@/components/RangeCalendar.vue";
import CalendarMixin from "@/mixins/Calendar";
export default {
  name: "Home",
  mixins: [CalendarMixin],
  meta() {
    return {
      title: this.$route.params.mp === "wb" ? "Wildberries 市场卖家的分析和统计, 竞争对手在 Wildberries 上的销售情况分析" : "OZON市场卖家的分析和统计, 竞争对手在OZON上的销售分析",
      meta: [{
        name: "description",
        content: this.$route.params.mp === "wb" ? "SalesFinder 是一项用于详细分析 Wildberries 竞争对手的服务: 分析品牌, 卖家, 产品和类别的销售额！" : "SalesFinder 是一项用于详细分析 OZON 竞争对手的服务: 分析品牌, 卖家, 产品和类别的销售额！"
      }]
    };
  },
  data() {
    return {};
  },
  components: {
    MainPage,
    MainTitle,
    EntitySearch,
    SellersTable
  },
  computed: {
    ratingBindings() {
      var _this$calendarBoundar;
      if (!this.calendarBoundaries) {
        return;
      }
      const lastAvailableDate = ((_this$calendarBoundar = this.calendarBoundaries) === null || _this$calendarBoundar === void 0 ? void 0 : _this$calendarBoundar[1]) || today();
      const weekRange = dateRangeFromDate(lastAvailableDate, 30);
      const [date, date2] = dateRangeBoundaries(weekRange, this.calendarBoundaries);
      return {
        action: "rating/getRatingSellers",
        id: this._uid,
        routeParams: this.$route.params,
        reportSettings: {
          mp: this.$route.params.mp,
          metric: "revenue",
          date,
          date2,
          size: 20
        },
        paginatable: false
      };
    }
  },
  methods: {
    searchLoader(query) {
      return this.$store.dispatch("seller/getSellerByName", {
        query,
        mp: this.$route.params.mp
      });
    },
    getRoute(item) {
      return {
        name: "SellerInfo",
        params: {
          s_id: item.s_id,
          mp: this.$route.params.mp
        }
      };
    }
  }
};